<template>
	<edit-template style="padding: 0 16rem;min-height: calc(100vh - 36rem - 76rem);background: #F9FAFD">


    <div class="body">
      <el-form ref="form" :model="form" label-position="left" label-width="180rem">
        <el-form-item label="任务名称" prop="name">
          <div style="display: flex;width: 33%">
            {{ form.name }}
          </div>
        </el-form-item>
        <el-form-item label="本次任务结束时间" prop="end_at">
          {{ form.end_at }}
        </el-form-item>
        <el-row>
          <el-col :span="7">
            <el-form-item label="单人最低合格率" prop="rate">
              <div style="width: 50%">
                {{ form.rate }}%
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="单人最高可辅导人数" prop="max_people">
              <div style="width: 50%">
                {{ form.max_people }}
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="适用校区" prop="school_id">
          {{ form.school_name }}
        </el-form-item>
        <el-form-item label="适用范围" prop="type">
          {{ Number(form.type) === 1 ? '所有年级' : Number(form.type) === 2 ? '单个年级' : Number(form.type) === 3 ? '单个教师' : '' }}
        </el-form-item>
        <el-form-item v-if="form.type+'' === '2'" label="适用年级" prop="grade_id">
          {{ form.range.join('') }}
        </el-form-item>
        <el-form-item v-if="form.type+'' === '3'" label="适用教师" prop="teacher_id">
          {{ form.range.join('') }}
        </el-form-item>
      </el-form>
      <template slot="footer">
        <el-button type="primary" @click="$router.back()">返回</el-button>
      </template>
    </div>

</edit-template>
</template>

<script>
export default {
	_config:{"route":{"path":"edit","meta":{"title":"编辑"}}},
  name: "add",
  data() {
    return {
      form: {},
    }
  },
  methods: {},
  created() {
    this.$_axios2.get('api/coach-task/task-detail', {params: {task_id: this.$route.query.id}}).then(res => {
      this.form = res.data.data
    })
  }
}
</script>

<style lang="scss" scoped>
.body {
  box-sizing: border-box;
  min-height: 366rem;
  margin-top: 12rem;
  padding: 24rem;
  border-radius: 8rem 8rem 8rem 8rem;
  background: #FFFFFF;
}

.el-form-item__label {
  font-size: 16rem;
  font-weight: 400;
  color: #666666;
}
</style>
